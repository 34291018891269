import React from 'react'
import { Option, OTab, OTabs } from '@dnvgl-onefoundation/onedesign-react'
import { SpinIndicator } from '@/components/layout'
import {
  BalanceOfPlant,
  WindFarmTotalMaterialBreakdown
} from '@/components/helpers'
import { config, helper } from '@/utils'
import { useProjectMaterialBreakdown, useTurbineOptions } from '@/hooks'
import MaterialBreakdown from './MaterialBreakdown'
import { TabIndex } from '@/hooks/useProjectMaterialBreakdown'
import {
  MaterialsBreakdownsViewModel,
  ProjectItemViewModel,
  ProjectType
} from '@/interfaces'
import {
  Select,
  Option as JoyOption,
  FormLabel,
  FormControl,
  Typography,
  Divider,
  Card
} from '@mui/joy'
import { Grid2 } from '@mui/material'
import ReInput from '@/components/helpers/ReInput'
import IconComponent from '@/components/helpers/IconComponent'

interface Props {
  isUpdating?: boolean
  isLoadingMaterialBreakdown?: boolean
  project: ProjectItemViewModel
  manufacturerOptions?: Option[]
  turbineOptions: Option[]
  turbineMaterialBreakdown?: MaterialsBreakdownsViewModel | null
  onUpdate: (propertyName: string, propertyValue: any) => void
}

const { toHtmlDate } = helper
const { icons } = config

const ProjectMaterialBreakdownSection = ({
  isUpdating,
  isLoadingMaterialBreakdown,
  project,
  turbineOptions,
  turbineMaterialBreakdown,
  onUpdate
}: Props) => {
  const { turbineApplication } = useTurbineOptions()
  const { isLoadingPlantWeights, plantWeights, selectedTab, setSelectedTab } =
    useProjectMaterialBreakdown(
      project.id,
      project.turbineId,
      project.projectType
    )

  const application = turbineApplication?.find(
    i => i.value === project?.turbineApplication
  )?.text

  return (
    <Card sx={{ backgroundColor: 'white', marginTop: '16px' }}>
      <Typography level="h4" sx={{ display: 'flex', alignItems: 'center' }}>
        <IconComponent iconClass={`${icons.materialBreakdown} fal mr-2`} />
        Material Breakdown
      </Typography>
      <Divider />
      <OTabs
        className={[
          'w-100',
          'tabs-bar-fix',
          'display-block',
          isUpdating ? 'is-disabled' : undefined
        ].join(' ')}
        selectedTab={selectedTab}
        onTabSelect={id => setSelectedTab(id as TabIndex)}>
        <OTab title="Turbine">
          <Typography level="h4" className="mb-4">
            Turbine
          </Typography>
          <Grid2 container gap={2} spacing={2}>
            {project?.turbineId ? (
              <Grid2 size={4}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Select
                    disabled={isUpdating || project.isLocked}
                    value={project.turbineId}
                    onChange={(_, value) => onUpdate('turbineId', value)}>
                    {turbineOptions.map(turbine => (
                      <JoyOption
                        key={turbine.text}
                        value={turbine.value}
                        placeholder="Please select">
                        {turbine.text}
                      </JoyOption>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
            ) : (
              <Grid2 size={12}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Select
                    disabled={isUpdating || project.isLocked}
                    onChange={(v, value) => onUpdate('turbineId', value)}>
                    {turbineOptions.map(turbine => (
                      <JoyOption
                        key={turbine.text}
                        value={turbine.value}
                        placeholder="Please select">
                        {turbine.text}
                      </JoyOption>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
            )}
            {!!project?.turbineId && (
              <>
                <ReInput
                  propertyName="turbineId"
                  label="Model"
                  gridSize={4}
                  readOnly
                  defaultValue={project?.turbineModelNumber}
                  disabled={isUpdating || project.isLocked}
                  variant="plain"
                />
                <ReInput
                  propertyName="manufacturer"
                  label="Manufacturer"
                  gridSize={4}
                  readOnly
                  defaultValue={project?.turbineManufacturer}
                  disabled={isUpdating || project.isLocked}
                  variant="plain"
                />
                <ReInput
                  propertyName="installationDate"
                  label="Installation date"
                  gridSize={4}
                  defaultValue={toHtmlDate(`${project?.installationDate}`)}
                  disabled={isUpdating || project.isLocked}
                  type="date"
                  onBlur={(n, v) => onUpdate(n, v)}
                />
                <ReInput
                  propertyName="numberOfTurbines"
                  label="Number of turbines"
                  gridSize={4}
                  defaultValue={`${project?.numberOfTurbines}`}
                  type="number"
                  disabled={isUpdating || project.isLocked}
                  onBlur={(n, v) => onUpdate(n, v || 1)}
                  slotProps={{ input: { min: 0 } }}
                  min={0}
                />
                <ReInput
                  propertyName="application"
                  label="Application"
                  disabled={isUpdating || project.isLocked}
                  gridSize={4}
                  defaultValue={application}
                  readOnly
                  variant="plain"
                />
                <Grid2 size={12} mt={2}>
                  <MaterialBreakdown
                    projectType={project?.projectType}
                    turbineId={project?.turbineId}
                    isLoading={isLoadingMaterialBreakdown}
                    data={turbineMaterialBreakdown}
                  />
                </Grid2>
              </>
            )}
          </Grid2>
        </OTab>
        <OTab
          title="Balance of plant"
          disabled={
            !project?.turbineId ||
            project.projectType === ProjectType.OffshoreFloating
          }>
          <h3 className="mt-0">Balance of plant</h3>
          {isLoadingPlantWeights ? (
            <SpinIndicator />
          ) : (
            <BalanceOfPlant data={plantWeights} project={project} />
          )}
        </OTab>
        <OTab
          title="Total wind farm"
          disabled={
            !project?.turbineId ||
            project.projectType === ProjectType.OffshoreFloating
          }>
          <h3 className="mt-0">Total wind farm</h3>
          {isLoadingPlantWeights ? (
            <SpinIndicator />
          ) : (
            <WindFarmTotalMaterialBreakdown
              layout="vertical"
              legendPosition="bottom"
              data={plantWeights?.totalWindFarmWeights}
              height={400}
              width={400}
            />
          )}
        </OTab>
      </OTabs>
    </Card>
  )
}

export default React.memo(ProjectMaterialBreakdownSection)
