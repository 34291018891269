import React from 'react'
import { SuperscriptUnit, TooltipHelpText } from '@/components/helpers'
import { config } from '@/utils'
import {
  OnshoreFoundationSpecificationViewModel,
  ProjectItemViewModel,
  SubstationViewModel
} from '@/interfaces'
import ProjectPageOnshoreSubstation from '@/components/projects/ProjectPageOnshoreSubstation'
import FoundationSpecification from './FoundationSpecification'
import {
  Card,
  Divider,
  FormControl,
  FormLabel,
  Option,
  Select,
  Typography
} from '@mui/joy'
import IconComponent from '@/components/helpers/IconComponent'
import { Grid2 } from '@mui/material'
import ReInput from '@/components/helpers/ReInput'
import ReRadioToggle from '@/components/helpers/ReRadioToggle'

interface Props {
  project: ProjectItemViewModel | null
  projectSubstations: SubstationViewModel | null
  onUpdate: (propertyName: string, propertyValue: any) => void
  onUpdateSubstation: (
    propertyName: string,
    propertyValue: any,
    type: string
  ) => void
  onUpdateFoundationSpecification: (
    propertyName?: string,
    propertyValue?: any
  ) => void
  disabled?: boolean
}

const { defaultSelectOption, projects, showNewSubstations } = config

const ProjectPageDetailsOnshore = ({
  project,
  onUpdate,
  projectSubstations,
  onUpdateSubstation,
  onUpdateFoundationSpecification,
  disabled
}: Props) => {
  const onshoreFoundations =
    project?.foundationSpecification as OnshoreFoundationSpecificationViewModel

  return (
    <Card sx={{ backgroundColor: 'white', marginTop: '16px' }}>
      <Typography level="h4" sx={{ display: 'flex', alignItems: 'center' }}>
        <IconComponent iconClass="fal fas fa-chart-network mr-2" />
        Site infrastructure
      </Typography>
      <Divider />
      <Typography level="title-md">Access roads</Typography>
      <Grid2 container gap={2} spacing={2} mb={1}>
        <ReInput
          propertyName="accessRoadLengthKm"
          gridSize={4}
          label="Access road length"
          defaultValue={project?.accessRoadLengthKm}
          onBlur={(n, v) => onUpdate(n, v)}
          helperText="km"
          type="number"
          slotProps={{
            input: {
              min: 0,
              type: 'number'
            }
          }}
          min={0}
          disabled={disabled}
        />
        <ReInput
          propertyName="accessRoadWidthM"
          label="Access road width"
          gridSize={4}
          defaultValue={project?.accessRoadWidthM}
          onBlur={(n, v) => onUpdate(n, v)}
          helperText="m"
          type="number"
          slotProps={{
            input: {
              min: 1,
              max: 100,
              type: 'number'
            }
          }}
          min={1}
          max={100}
          disabled={disabled}
        />
        <ReInput
          propertyName="accessRoadDepthM"
          label={
            <>
              Access road depth
              <TooltipHelpText
                className="ml-1"
                message="Min: 0.3m, Max: 10m, the standard is usually between 0.3 and 0.5m"
                placement="left"
              />
            </>
          }
          gridSize={4}
          defaultValue={project?.accessRoadDepthM}
          onBlur={(n, v) => onUpdate(n, v)}
          helperText="m"
          min={0.3}
          max={10}
          disabled={disabled}
          type="number"
        />
        <Divider sx={{ width: 'calc(100% + 32px)', left: '-16px' }} />
        <FoundationSpecification
          projectType={project?.projectType}
          foundationSpecification={onshoreFoundations}
          isOptional={true}
          onUpdate={onUpdateFoundationSpecification}
          disabled={disabled}>
          <ReInput
            propertyName="foundationVolume"
            label={
              <>
                Foundation volume
                <TooltipHelpText
                  className="ml-1"
                  message="Foundation volume for a single turbine"
                  placement="left"
                />
              </>
            }
            helperText={(<SuperscriptUnit unit="m" power={3} />) as any}
            type="number"
            defaultValue={onshoreFoundations?.foundationVolume}
            slotProps={{ input: { min: 0 } }}
            onBlur={(n, v) => onUpdateFoundationSpecification(n, v)}
            disabled={disabled}
            min={0}
          />
        </FoundationSpecification>
        <Divider sx={{ width: 'calc(100% + 32px)', left: '-16px' }} />
        <Typography level="title-md" width="100%">
          Crane pads
        </Typography>
        <ReInput
          propertyName="cranePadLengthM"
          gridSize={4}
          label="Crane pad length"
          helperText="m"
          type="number"
          defaultValue={project?.cranePadLengthM}
          slotProps={{ input: { min: 0, max: 1000 } }}
          onBlur={(n, v) => onUpdate(n, v)}
          disabled={disabled}
          min={0}
          max={1000}
        />
        <ReInput
          propertyName="cranePadWidthM"
          gridSize={4}
          label="Crane pad width"
          helperText="m"
          type="number"
          defaultValue={project?.cranePadWidthM}
          slotProps={{ input: { min: 0, max: 1000 } }}
          onBlur={(n, v) => onUpdate(n, v)}
          disabled={disabled}
          min={0}
          max={1000}
        />
        <ReInput
          propertyName="cranePadDepthM"
          gridSize={4}
          label={
            <>
              Crane pad depth
              <TooltipHelpText
                className="ml-1"
                message="Min: 0.3m, Max: 10m, the standard is usually between 0.3 and 0.5m"
                placement="left"
              />
            </>
          }
          helperText="m"
          type="number"
          defaultValue={project?.cranePadDepthM}
          slotProps={{ input: { min: 0, max: 100 } }}
          onBlur={(n, v) => onUpdate(n, v)}
          disabled={disabled}
          min={0}
          max={100}
        />
        <Divider sx={{ width: 'calc(100% + 32px)', left: '-16px' }} />
        <Typography level="title-md" width="100%">
          Cables
        </Typography>
        <ReInput
          propertyName="arrayCableLengthKm"
          gridSize={6}
          label="Array cable length"
          helperText="km"
          type="number"
          defaultValue={project?.arrayCableLengthKm}
          slotProps={{ input: { min: 0 } }}
          onBlur={(n, v) => onUpdate(n, v)}
          disabled={disabled}
          min={0}
        />
        <ReInput
          propertyName="exportCableLengthKm"
          gridSize={6}
          label="Export cable length"
          helperText="km"
          type="number"
          defaultValue={project?.exportCableLengthKm}
          slotProps={{ input: { min: 0 } }}
          onBlur={(n, v) => onUpdate(n, v)}
          disabled={disabled}
          min={0}
        />
        <Grid2 size={6}>
          <FormControl>
            <FormLabel>Array cable size</FormLabel>
            <Select
              defaultValue={project?.arrayCableSizeKV}
              disabled={disabled}
              onChange={(_, v) => onUpdate('arrayCableSizeKV', v)}>
              {projects.selectOptions.cableSize.map(size => (
                <Option key={size.text} value={size?.value}>
                  {size.text}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl>
            <FormLabel>Export cable size</FormLabel>
            <Select
              defaultValue={project?.exportCableSizeKV}
              disabled={disabled}
              onChange={(_, v) => onUpdate('exportCableSizeKV', v)}>
              {projects.selectOptions.cableSize.map(size => (
                <Option key={size.text} value={size?.value}>
                  {size.text}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl>
            <FormLabel>Array cable core material</FormLabel>
            <Select
              defaultValue={project?.arrayCableMaterial}
              disabled={disabled}
              onChange={(_, v) => onUpdate('arrayCableMaterial', v)}>
              {project?.arrayCableMaterial === null
                ? [defaultSelectOption, ...projects.selectOptions.cableMaterial]
                : projects.selectOptions.cableMaterial.map(material => (
                    <Option key={material.text} value={material?.value}>
                      {material.text}
                    </Option>
                  ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl>
            <FormLabel>Export cable core material</FormLabel>
            <Select
              defaultValue={project?.exportCableMaterial}
              disabled={disabled}
              onChange={(_, v) => onUpdate('exportCableMaterial', v)}>
              {project?.exportCableMaterial === null
                ? [defaultSelectOption, ...projects.selectOptions.cableMaterial]
                : projects.selectOptions.cableMaterial.map(material => (
                    <Option key={material.text} value={material?.value}>
                      {material.text}
                    </Option>
                  ))}
            </Select>
          </FormControl>
        </Grid2>
        <Divider sx={{ width: 'calc(100% + 32px)', left: '-16px' }} />
        <Typography level="title-md" width="100%">
          Met Masts
        </Typography>
        <ReInput
          propertyName="metMastNumber"
          gridSize={6}
          label="Met mast number"
          type="number"
          defaultValue={project?.metMastNumber}
          slotProps={{ input: { min: 0, max: 100 } }}
          onBlur={(n, v) => onUpdate(n, v)}
          disabled={disabled}
          min={0}
          max={100}
        />
        <Divider sx={{ width: 'calc(100% + 32px)', left: '-16px' }} />
        <Typography level="title-md" width="100%">
          Substations
        </Typography>
        {showNewSubstations ? (
          <ProjectPageOnshoreSubstation
            project={project}
            onUpdate={onUpdate}
            onUpdateSubstations={onUpdateSubstation}
            projectSubstations={projectSubstations}
          />
        ) : (
          <ReRadioToggle
            label="Is there an on-site substation?"
            disabled={disabled}
            selectedValue={project?.hasOnshoreSubstation}
            handleChange={() =>
              onUpdate('hasOnshoreSubstation', !project?.hasOnshoreSubstation)
            }
          />
        )}
      </Grid2>
    </Card>
  )
}

export default React.memo(ProjectPageDetailsOnshore)
