import React, { useEffect } from 'react'
import { useCostScenarioBreakdown, useScenarioUpdate } from '@/hooks'
import {
  ProjectItemViewModel,
  ProjectType,
  ScenarioItemViewModel
} from '@/interfaces'
import DefineScopeOnshore from './DefineScopeOnshore'
import DefineScopeOffshoreFixed from './DefineScopeOffshoreFixed'
import DefineScopeOffshoreFloating from './DefineScopeOffshoreFloating'
import { CostScenarioBreakdown } from '../helpers'
import { config } from '@/utils'
import FloatingAlertMessage from '@/components/helpers/FloatingAlertMessage'

interface Props {
  project?: ProjectItemViewModel | null
  scenario?: ScenarioItemViewModel | null
  isSuperAdministrator?: boolean
}

const { hideFloatingModel } = config
const DefineScopeStep = ({
  project,
  scenario,
  isSuperAdministrator
}: Props) => {
  const { onPropertyUpdate, onScenarioUpdate } = useScenarioUpdate()
  const {
    costScenarioBreakdownTableItems,
    costScenarioBreakdownChartData,
    isManual,
    getCostScenarioBreakdown
  } = useCostScenarioBreakdown(false, scenario?.includeScrapValue, scenario?.includeContingencyAllocation)

  const getScopeType = () => {
    if (project?.projectType === ProjectType.OffshoreBottomFixed)
      return 'offshoreBottomFixed'

    if (project?.projectType === ProjectType.OffshoreFloating)
      return 'offshoreFloating'

    if (project?.projectType === ProjectType.Onshore) return 'onshore'

    return undefined
  }
  const onScopeUpdate = (name: string, value?: boolean) => {
    const $type = getScopeType()
    if (!!$type)
      onPropertyUpdate(
        'decommissionScope',
        { $type, [name]: !!value },
        `${scenario?.id}`
      )
  }
  const onScenarioScopeUpdate = (payload: any) => {
    const $type = getScopeType()
    if (!!$type)
      onScenarioUpdate(
        { decommissionScope: { $type, ...payload } },
        `${scenario?.id}`
      )
  }

  const toggleIncludeScrapValue = () =>
    onPropertyUpdate(
      'includeScrapValue',
      !scenario?.includeScrapValue,
      `${scenario?.id}`
    )

    const toggleIncludeContingencyAllocation = () =>
      onPropertyUpdate(
        'includeContingencyAllocation',
        !scenario?.includeContingencyAllocation,
        `${scenario?.id}`
      )

  const changeCurrency = (currencyId?: string) =>
    onPropertyUpdate('currencyId', currencyId, `${scenario?.id}`)

  useEffect(() => {
    if (
      scenario?.id &&
      project?.projectType !== undefined &&
      project.projectType !== ProjectType.OffshoreFloating
    )
      getCostScenarioBreakdown(scenario?.id, project?.projectType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, scenario])

  if (project?.projectType === ProjectType.Onshore)
    return (
      <DefineScopeOnshore
        scenario={scenario}
        onScopeUpdate={onScopeUpdate}
        onScenarioUpdate={onScenarioScopeUpdate}>
        <CostScenarioBreakdown
          scenario={scenario}
          costScenarioBreakdownChartData={costScenarioBreakdownChartData}
          costScenarioBreakdownTableItems={costScenarioBreakdownTableItems}
          includeScrapValue={scenario?.includeScrapValue}
          includeContingencyAllocation={scenario?.includeContingencyAllocation}
          isManual={isManual}
          isSuperAdmin={isSuperAdministrator}
          project={project}
          toggleIncludeScrapValue={toggleIncludeScrapValue}
          toggleincludeContingencyAllocation={toggleIncludeContingencyAllocation}
          changeCurrency={changeCurrency}
        />
      </DefineScopeOnshore>
    )

  if (project?.projectType === ProjectType.OffshoreBottomFixed)
    return (
      <DefineScopeOffshoreFixed
        project={project}
        scenario={scenario}
        onScopeUpdate={onScopeUpdate}>
        <CostScenarioBreakdown
          scenario={scenario}
          costScenarioBreakdownChartData={costScenarioBreakdownChartData}
          costScenarioBreakdownTableItems={costScenarioBreakdownTableItems}
          includeScrapValue={scenario?.includeScrapValue}
          isManual={isManual}
          isSuperAdmin={isSuperAdministrator}
          project={project}
          toggleIncludeScrapValue={toggleIncludeScrapValue}
          changeCurrency={changeCurrency}
        />
      </DefineScopeOffshoreFixed>
    )

  if (project?.projectType === ProjectType.OffshoreFloating)
    return (
      <>
        {hideFloatingModel ? (
          <FloatingAlertMessage />
        ) : (
          <DefineScopeOffshoreFloating
            project={project}
            scenario={scenario}
            onScopeUpdate={onScopeUpdate}>
            <CostScenarioBreakdown
              scenario={scenario}
              costScenarioBreakdownChartData={costScenarioBreakdownChartData}
              costScenarioBreakdownTableItems={costScenarioBreakdownTableItems}
              includeScrapValue={scenario?.includeScrapValue}
              isManual={isManual}
              isSuperAdmin={isSuperAdministrator}
              project={project}
              toggleIncludeScrapValue={toggleIncludeScrapValue}
              changeCurrency={changeCurrency}
            />
          </DefineScopeOffshoreFloating>
        )}
      </>
    )

  return null
}

export default React.memo(DefineScopeStep)
