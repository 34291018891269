import { FoundationSpecificationViewModel, ProjectType } from '@/interfaces'
import { config, helper, tooltips } from '@/utils'
import React, { ReactNode, useState } from 'react'
import { Box, Typography } from '@mui/joy'
import { Grid2 } from '@mui/material'
import ReInput from '@/components/helpers/ReInput'
import ReRadioToggle from '@/components/helpers/ReRadioToggle'

const { addPluralS } = helper

interface Props {
  foundationSpecification: FoundationSpecificationViewModel | undefined
  isOptional?: boolean
  children?: ReactNode
  onUpdate?: (
    propertyName?: string,
    propertyValue?: string | number | boolean
  ) => void
  disabled?: boolean
  projectType?: ProjectType
}

const FoundationSpecification = ({
  foundationSpecification,
  isOptional,
  children,
  onUpdate,
  disabled,
  projectType
}: Props) => {
  const [showOptional, setShowOptional] = useState(
    !isOptional || (foundationSpecification?.totalWeight ?? 0) > 0
  )

  return (
    <>
      <Typography level="title-md" width="100%">
        Foundation
      </Typography>
      <Grid2 size={6}>{children}</Grid2>
      <Grid2 size={6}>
        {isOptional && (
          <ReRadioToggle
            label="Input foundation weight"
            disabled={disabled}
            selectedValue={showOptional}
            tooltip={tooltips.project.FoundationWeights}
            handleChange={() => {
              setShowOptional(!showOptional)
              if (showOptional) {
                onUpdate?.()
              }
            }}
          />
        )}
        {showOptional && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              marginTop: projectType === ProjectType.Onshore ? 2 : 0
            }}>
            <ReInput
              propertyName="steel"
              label="Foundation steel weight"
              helperText="tonnes"
              type="number"
              defaultValue={foundationSpecification?.steel}
              slotProps={{ input: { min: 0 } }}
              onBlur={(n, v) => onUpdate?.(n, v)}
              disabled={disabled}
            />
            <ReInput
              propertyName="concrete"
              label="Foundation concrete weight"
              helperText="tonnes"
              defaultValue={foundationSpecification?.concrete}
              type="number"
              slotProps={{ input: { min: 0 } }}
              onBlur={(n, v) => onUpdate?.(n, v)}
              disabled={disabled}
            />
            <ReInput
              propertyName="other"
              label="Foundation other weight"
              helperText="tonnes"
              defaultValue={foundationSpecification?.other}
              type="number"
              slotProps={{ input: { min: 0 } }}
              min={0}
              onBlur={(n, v) => onUpdate?.(n, parseFloat(v))}
              disabled={disabled}
            />
            <ReInput
              propertyName="totalWeight"
              label="Foundation total weight"
              defaultValue={
                foundationSpecification?.totalWeight
                  ? `${foundationSpecification?.totalWeight} tonne${addPluralS(
                      foundationSpecification?.totalWeight || 0
                    )}`
                  : config.missingValueCharacter
              }
              variant="plain"
              readOnly
            />
          </Box>
        )}
      </Grid2>
    </>
  )
}

export default FoundationSpecification
