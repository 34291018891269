import React from 'react'
import { config } from '@/utils'
import {
  OffshoreBottomFixedFoundationSpecificationViewModel,
  ProjectItemViewModel,
  SubstationViewModel
} from '@/interfaces'
import {
  ProjectPageOffshoreSubstation,
  ProjectPageOnshoreSubstation
} from '@/components/projects/index'
import FoundationSpecification from './FoundationSpecification'
import {
  Card,
  Divider,
  FormControl,
  FormLabel,
  Option,
  Select,
  Typography
} from '@mui/joy'
import IconComponent from '@/components/helpers/IconComponent'
import ReInput from '@/components/helpers/ReInput'
import { Grid2 } from '@mui/material'
import ReRadioToggle from '@/components/helpers/ReRadioToggle'

interface Props {
  project: ProjectItemViewModel | null
  projectSubstations: SubstationViewModel | null
  onUpdate: (
    propertyName: string,
    propertyValue: string | number | boolean
  ) => void
  onUpdateSubstation: (
    propertyName: string,
    propertyValue: any,
    type: string
  ) => void
  onUpdateFoundationSpecification: (
    propertyName?: string,
    propertyValue?: any
  ) => void
  disabled?: boolean
}

const { defaultSelectOption, projects, showNewSubstations } = config

const ProjectPageDetailsOffshoreFixed = ({
  project,
  onUpdate,
  projectSubstations,
  onUpdateSubstation,
  onUpdateFoundationSpecification,
  disabled
}: Props) => {
  const foundationSpecification =
    project?.foundationSpecification as OffshoreBottomFixedFoundationSpecificationViewModel
  const foundationOptions =
    foundationSpecification.foundationType === null
      ? [defaultSelectOption, ...projects.selectOptions.foundationType]
      : projects.selectOptions.foundationType
  const cableMaterial =
    project?.exportCableMaterial === null
      ? [defaultSelectOption, ...projects.selectOptions.cableMaterial]
      : projects.selectOptions.cableMaterial
  return (
    <Card sx={{ backgroundColor: 'white', marginTop: '16px' }}>
      <Typography level="h4" sx={{ display: 'flex', alignItems: 'center' }}>
        <IconComponent iconClass="fal fas fa-chart-network mr-2" />
        Site infrastructure
      </Typography>
      <Divider />
      <Grid2 container gap={2} spacing={2} mb={1}>
        <Typography level="title-md" width="100%">
          General
        </Typography>
        <ReInput
          gridSize={6}
          propertyName="distanceToPortKm"
          label="Distance to port"
          helperText="Distance (km) to port from centre of wind farm"
          type="number"
          defaultValue={project?.distanceToPortKm}
          min={0}
          slotProps={{
            input: {
              min: 0,
              type: 'number'
            }
          }}
          onBlur={(n, v) => onUpdate(n, v)}
          disabled={disabled}
        />
        <ReInput
          gridSize={6}
          propertyName="averageWaterDepthM"
          label="Average water depth"
          helperText="m"
          type="number"
          defaultValue={project?.averageWaterDepthM}
          min={0}
          max={10000}
          disabled={disabled}
          onBlur={(n, v) => onUpdate(n, v)}
        />
        <Divider sx={{ width: 'calc(100% + 32px)', left: '-16px' }} />
        <FoundationSpecification
          projectType={project?.projectType}
          foundationSpecification={foundationSpecification}
          onUpdate={onUpdateFoundationSpecification}
          disabled={disabled}>
          <>
            <FormControl>
              <FormLabel>Foundation type</FormLabel>
              <Select
                value={foundationSpecification?.foundationType}
                disabled={disabled}
                onChange={(_, v) =>
                  onUpdateFoundationSpecification('foundationType', v)
                }>
                {foundationOptions.map(size => (
                  <Option key={size.text} value={size?.value}>
                    {size.text}
                  </Option>
                ))}
              </Select>
            </FormControl>
          </>
        </FoundationSpecification>
        <Divider sx={{ width: 'calc(100% + 32px)', left: '-16px' }} />
        <Typography level="title-md" width="100%">
          Cables
        </Typography>
        <ReInput
          gridSize={6}
          propertyName="arrayCableLengthKm"
          label="Array cable length"
          helperText="km"
          type="number"
          disabled={disabled}
          defaultValue={project?.arrayCableLengthKm}
          min={0}
          slotProps={{ input: { min: 0 } }}
          onBlur={(n, v) => onUpdate(n, v)}
        />
        <ReInput
          gridSize={6}
          propertyName="exportCableLengthKm"
          label="Export cable length"
          helperText="km"
          type="number"
          disabled={disabled}
          defaultValue={project?.exportCableLengthKm}
          min={0}
          slotProps={{ input: { min: 0 } }}
          onBlur={(n, v) => onUpdate(n, v)}
        />
        <Grid2 size={6}>
          <FormControl>
            <FormLabel>Array cable size</FormLabel>
            <Select
              value={project?.arrayCableSizeKV}
              disabled={disabled}
              onChange={(_, v) =>
                onUpdate('arrayCableSizeKV', parseInt(`${v}`))
              }>
              {projects.selectOptions.cableSize.map(size => (
                <Option key={size.text} value={size?.value}>
                  {size.text}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl>
            <FormLabel>Export cable size</FormLabel>
            <Select
              value={project?.exportCableSizeKV}
              disabled={disabled}
              onChange={(_, v) =>
                onUpdate('exportCableSizeKV', parseInt(`${v}`))
              }>
              {projects.selectOptions.cableSize.map(size => (
                <Option key={size.text} value={size?.value}>
                  {size.text}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl>
            <FormLabel>Array cable core material</FormLabel>
            <Select
              value={project?.arrayCableMaterial}
              disabled={disabled}
              onChange={(_, v) =>
                onUpdate('arrayCableMaterial', parseInt(`${v}`))
              }>
              {cableMaterial.map(material => (
                <Option key={material.text} value={material?.value}>
                  {material.text}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl>
            <FormLabel>Export cable core material</FormLabel>
            <Select
              value={project?.exportCableMaterial}
              disabled={disabled}
              onChange={(_, v) =>
                onUpdate('exportCableMaterial', parseInt(`${v}`))
              }>
              {cableMaterial.map(material => (
                <Option key={material.text} value={material?.value}>
                  {material.text}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Divider sx={{ width: 'calc(100% + 32px)', left: '-16px' }} />
        <Typography level="title-md" width="100%">
          Substations
        </Typography>
        {showNewSubstations ? (
          <>
            <ProjectPageOffshoreSubstation
              project={project}
              onUpdate={onUpdate}
              onUpdateSubstations={onUpdateSubstation}
              projectSubstations={projectSubstations}
            />
            <ProjectPageOnshoreSubstation
              project={project}
              onUpdate={onUpdate}
              onUpdateSubstations={onUpdateSubstation}
              projectSubstations={projectSubstations}
            />
          </>
        ) : (
          <ReRadioToggle
            label="Offshore substation"
            selectedValue={project?.hasOffshoreSubstation}
            disabled={disabled}
            handleChange={() => {
              onUpdate('hasOffshoreSubstation', !project?.hasOffshoreSubstation)
            }}
          />
        )}
      </Grid2>
    </Card>
  )
}

export default React.memo(ProjectPageDetailsOffshoreFixed)
