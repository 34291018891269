import React, { ReactNode, useEffect } from 'react'
import {
  OCol,
  ORow,
  OToggleSwitch,
  OWizard,
  OWizardStep
} from '@dnvgl-onefoundation/onedesign-react'
import { Link } from 'react-router-dom'
import { ContentSection, Page } from '@/components/layout'
import {
  ChooseProjectStep,
  DefineScopeStep,
  SelectProvidersStep,
  SummaryStep
} from '@/components/planning'
import {
  BreadcrumbsTitle,
  LockButton,
  ModificationInfo,
  PageNotFound,
  SaveButton
} from '@/components/helpers'
import { config, helper } from '@/utils'
import {
  useCircularityRateData,
  useCountries,
  useProviders,
  useScenario,
  useScenarioStepsAvailability
} from '@/hooks'
import AdminUpdateCalculationResults from '@/components/admin/calculation/AdminUpdateCalculationResults'
import { AppUser, ProjectType } from '@/interfaces'
import { Button } from '@mui/joy'
import IconComponent from '@/components/helpers/IconComponent'
import { Grid2 } from '@mui/material'

const { urls } = config
const { isSuperAdministrator } = helper

interface Props {
  currentUser: AppUser | null
}

interface PlanningWizardStepProps {
  title?: string
  actions?: ReactNode
  children?: ReactNode
}

const PlanningWizardStep = ({
  title,
  actions,
  children
}: PlanningWizardStepProps) => (
  <ORow className="pt-0 px-4">
    <OCol md={actions ? '8' : '12'}>
      <h2>{title}</h2>
    </OCol>
    {!!actions && (
      <OCol md="4" className="text-right mt-3">
        {actions}
      </OCol>
    )}
    <OCol md="12" className="wizard-step-content">
      {children}
    </OCol>
  </ORow>
)

const ScenarioPage = ({ currentUser }: Props) => {
  const {
    isLoading,
    isNotFound,
    scenarioId,
    scenario,
    projectItems,
    isLoadingProjects,
    selectedProject,
    selectedStep,
    turbineMaterialBreakdown,
    onPropertyUpdate,
    onUpdateSelectedProject,
    onRemove,
    onRename,
    onLockChange,
    onStepChanged,
    onSave,
    onSaveToDrafts,
    onSaveAndClose
  } = useScenario()

  const { countryOptions } = useCountries()
  const { filteredItems } = useProviders()
  const { scenarioCircularityRate, getScenarioCircularityRateData } =
    useCircularityRateData()
  const {
    isProvidersStepDisabled,
    isDefineScopeStepDisabled,
    isSummaryStepDisabled
  } = useScenarioStepsAvailability(scenario)

  useEffect(() => {
    if (
      scenario?.project?.id &&
      scenario?.project.projectType !== ProjectType.OffshoreFloating
    )
      getScenarioCircularityRateData(scenarioId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject])

  return (
    <Page
      title={
        <BreadcrumbsTitle
          disabled={isLoading}
          isDraft={scenario?.isDraft}
          isLocked={scenario?.isLocked}
          title={scenario?.name}
          backLink={urls.scenarios}
          backName="Scenarios"
        />
      }
      isH1Title={false}
      actions={
        !isLoading &&
        !isNotFound && (
          <Grid2 container gap="4px">
            {!scenario?.isLocked && (
              <Link to={[urls.settingsScenarios, scenario?.id].join('/')}>
                <Button
                  disabled={isLoading}
                  variant="plain"
                  startDecorator={
                    <IconComponent
                      iconClass="fal fa-calculator-alt"
                      size={20}
                    />
                  }>
                  Open settings
                </Button>
              </Link>
            )}
            {!!scenario?.id && !scenario?.isLocked && (
              <Button
                disabled={isLoading}
                variant="plain"
                onClick={onRename}
                startDecorator={
                  <IconComponent iconClass="fas fa-pen" size={20} />
                }>
                Rename
              </Button>
            )}
            {!!scenario?.id && (
              <LockButton
                isLoading={isLoading}
                isLocked={scenario?.isLocked}
                onClick={() => onLockChange(!scenario?.isLocked)}
              />
            )}
            {isSuperAdministrator(currentUser) &&
              !scenario?.isLocked &&
              selectedProject?.projectType !== undefined && (
                <AdminUpdateCalculationResults
                  projectType={selectedProject.projectType}
                  scenario={scenario}
                />
              )}
            {!!scenario?.project?.id &&
              scenario.project.projectType !== ProjectType.OffshoreFloating && (
                <Link to={`${urls.scenarios}/${scenario?.id}${urls.reports}`}>
                  <Button>Full report</Button>
                </Link>
              )}
            {!!scenario?.id && !scenario?.isLocked && (
              <SaveButton
                onSave={onSave}
                onSaveAndClose={onSaveAndClose}
                onSaveToDrafts={onSaveToDrafts}
              />
            )}
          </Grid2>
        )
      }>
      {isNotFound ? (
        <ORow>
          <PageNotFound />
        </ORow>
      ) : (
        <>
          <ModificationInfo
            createdAt={scenario?.createdAt}
            createdBy={scenario?.createdBy}
            updatedAt={scenario?.updatedAt}
            updatedBy={scenario?.updatedBy}
          />
          <ContentSection>
            <OWizard
              className={`overflow-hidden btnFinish-hidden ${
                scenario?.isLocked ? 'btnCancel-hidden' : ''
              }`}
              fullPageWizard={false}
              selectedStep={selectedStep}
              btnCancelText={
                <div className="btn btn-flat btn-sm">
                  <i className="fas fa-trash text-danger mr-2" />
                  {scenario?.isDraft ? 'Discard draft' : 'Remove scenario'}
                </div>
              }
              onCancelled={onRemove}
              onStepChanged={onStepChanged}>
              <OWizardStep title="Choose project">
                <PlanningWizardStep title="Choose project">
                  {!!projectItems?.length && (
                    <ChooseProjectStep
                      items={projectItems}
                      isLoading={isLoadingProjects}
                      isChangingProjectDisabled={
                        !!(
                          scenario?.providers?.metal?.provider?.id ||
                          scenario?.providers?.blade?.provider?.id ||
                          scenario?.providers?.concrete?.provider?.id ||
                          scenario?.isLocked
                        )
                      }
                      selectedProject={selectedProject}
                      countryOptions={countryOptions}
                      onUpdateSelectedProject={onUpdateSelectedProject}>
                      <div className="border-bottom py-2">
                        <i className="fas fa-chart-bar  ml-3 mr-2" />
                        <strong>Report</strong>
                      </div>
                      <div style={{ marginTop: '5px' }}>
                        <label>
                          Present Assumed End of Life date in the report?
                        </label>
                        <OToggleSwitch
                          className="pointer mb-3"
                          textLocation="hidden"
                          checked={scenario?.includeAssumedEndOfLifeDate}
                          disabled={scenario?.isLocked}
                          onChange={() =>
                            onPropertyUpdate?.(
                              'includeAssumedEndOfLifeDate',
                              !scenario?.includeAssumedEndOfLifeDate,
                              scenario?.id || ''
                            )
                          }>
                          {`${
                            scenario?.includeAssumedEndOfLifeDate ? 'Yes' : 'No'
                          }`}
                        </OToggleSwitch>
                      </div>
                    </ChooseProjectStep>
                  )}
                </PlanningWizardStep>
              </OWizardStep>
              <OWizardStep
                title="Select providers"
                disabled={isProvidersStepDisabled}>
                <PlanningWizardStep title="Select providers">
                  <SelectProvidersStep
                    isNextStepDisabled={isDefineScopeStepDisabled}
                    isLocked={scenario?.isLocked}
                  />
                </PlanningWizardStep>
              </OWizardStep>
              <OWizardStep
                title="Define scope"
                disabled={isDefineScopeStepDisabled}>
                <PlanningWizardStep>
                  <DefineScopeStep
                    project={selectedProject}
                    scenario={scenario}
                    isSuperAdministrator={isSuperAdministrator(currentUser)}
                  />
                </PlanningWizardStep>
              </OWizardStep>
              <OWizardStep title="Summary" disabled={isSummaryStepDisabled}>
                <PlanningWizardStep title="Summary">
                  <SummaryStep
                    selectedProject={selectedProject}
                    scenario={scenario}
                    turbineMaterialBreakdown={turbineMaterialBreakdown}
                    providerItems={filteredItems}
                    turbineCircularityRateForScenario={
                      scenarioCircularityRate?.circularityWeights
                        .windfarmCircularityRate
                    }
                  />
                </PlanningWizardStep>
              </OWizardStep>
            </OWizard>
          </ContentSection>
        </>
      )}
    </Page>
  )
}

export default React.memo(ScenarioPage)
